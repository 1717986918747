import { Link as RouterLink } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import {  useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Button, Container, Divider, Link, List, ListItem, ListItemIcon, ListItemText, Modal, Paper, Stack, Typography, useTheme } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Carousel from 'react-material-ui-carousel';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { toDate as datefnstz_toDate } from 'date-fns-tz';
import Grid from '@mui/material/Unstable_Grid2';

import items from './HomeData';
import SpeedSvg from './svg/speed.svg';
import BoltSvg from './svg/bolt.svg';
import TrustSvg from './svg/trust.svg';
import ShieldSvg from './svg/shield.svg';
import EaseSvg from './svg/ease.svg';
import UmbrellaSvg from './svg/umbrella.svg';
import RevelataSystemSvg from './svg/revelata_system.svg';
import AuditUiSvg from './svg/audit_ui.svg';
import ExcelIntegrationSvg from './svg/excel_integration.svg';
import RevelataLogoSvg from "./svg/revelata_heptagonal_rose-logo_with_company_name.svg";
import HomeGradientSvg from './svg/home_page_gradient.svg';
import { useEffect, useState } from "react";
import { ampli } from '../ampli/index.ts';

Highcharts.setOptions(
    {
        lang: {
            thousandsSep: ',',
            numericSymbols: ["k", "M", "B", "T", "P", "E"]
        }
    }
)



export default function Home() {
    const muiTheme = useTheme();
    const { route, user } = useAuthenticator((context) => [context.route, context.user]);
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);

    function handleSignUpModalOpen() {
        setOpen(true);
    }

    function handleSignUpModalClose() {
        setOpen(false);
    }
    let from = location.state?.from?.pathname || '/app';
    useEffect(() => {
        if (route === 'authenticated') {
            ampli.identify(user.username);
            ampli.login();
            navigate(from, { replace: true });
        }
    });

    function AlphaModal() {
        return (
            <Modal open={open} onClose={handleSignUpModalClose} style={{ backdropFilter: "blur(5px)"}}>
                <Paper 
                    elevation={24}
                    sx={{
                    position: 'absolute', 
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '16px',
                    p: 10, 
                    maxWidth: "600px"                           
                }}>
                    <Typography variant="h4" color="var(--mui-palette-primary-dark)">
                        Thank you for your interest!
                    </Typography>
                    <Typography  sx={{ mt: 2 }}>
                        deepKPI is in private Alpha at the moment.  Please check back later to join our next cohort of early adopters!
                    </Typography>
                </Paper>
            </Modal>
        )
    }

    function formatDatapoints(datapoints) {
        return datapoints.map(
            (dp) => {                                
                const out = {
                    x: datefnstz_toDate(dp['displayDatetime'] + "T00:00:00", { timeZone: 'America/New_York'}).getTime(),
                    y: Number.parseFloat(dp['displayValue']),
                    marker: { radius: 5 },
                }
                // console.log(dp, out);
                return out;
            }
        ); 
    }

    function CarouselItem(props) {
        // console.log(props);
        const minX = datefnstz_toDate(props.data.minX + "T00:00:00", { timeZone: 'America/New_York'}).getTime();
        const maxX = datefnstz_toDate(props.data.maxX + "T00:00:00", { timeZone: 'America/New_York'}).getTime();

        const hcOptions = {
            credits: { enabled: false },                    
            xAxis: {            
                type: "datetime",
                className: "revelata-highcharts-font",
                labels: { 
                    style: { 
                        color: muiTheme.palette.text.primary, 
                        cursor: "default",
                        fontSize: "0.8rem"
                    }
                },
                min: minX, 
                max: maxX            
            },
            yAxis: {
                title: {
                    text: null,
                },
                className: "revelata-highcharts-font",
                labels: { 
                    style: { 
                        color: muiTheme.palette.text.primary, 
                        cursor: "default",
                        fontSize: "0.8rem"
                    }
                }
            },
            tooltip: {
                xDateFormat: "%Y-%m-%d",
                pointFormat: "{point.y}",
                //pointFormatter: function () { return data['data']['u'] + this.y },
                className: "highcharts-light revelata-highcharts-font"
            },
            series: [
                {   
                    name: '',
                    data: formatDatapoints(props.data.ts),//formatSeriesForHighCharts(data, selectedDatapoint && selectedDatapoint['chartId'] !== idx),  
                    color: 'var(--brand-main)',                              
                }
            ], 
            title: { 
                text: props.name, 
                style: {
                    fontSize: props.fontSize,
                    fontWeight: "400", 
                    fontFamily: "Figtree",
                    color: "var(--brand-analogous-50)"
                },                
            }, 
            legend:{ enabled: false },
            accessibility: {
                enabled: false,
            },
            chart: {
                borderRadius: "16px",                
                styleMode: true,                
                type: "line", //"spline"
                height: props.plotHeight,                
                time: {
                    timeZone: "America/New_York"
                }, 
                spacing: [20, 20, 20, 20]

            },
            plotOptions: {
                series: {                    
                    marker: {
                      enabled: true
                    }
                }
                // spline: { allowPointSelect: true },
                // line: { allowPointSelect: false }, 
                // series: {   
                //     marker: {
                //         states: {
                //             select: {
                //                 fillColor: '#ff0000',
                //             }
                //         }
                //     },         
                //     point:  { events: { select: function() {                    
                //         setSelectedDatapoint({'chartId': idx, 'dp': this.custom});                    
                //     } } }
                // }
            }
        }

        return (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={hcOptions}
                    containerProps = {{ className: 'highcharts-dark revelata-highcharts-font' }}
                />
        )
    }

    function ResponsiveLede() {
        const text_headline = <>All the KPIs<br/>you could ever want</>
        const text_subheadline = <>We surface public company KPIs from 10-K, 10-Q, and 8-K filings, so you can get to insight faster.</>
        const text_plotheading = <>Quickly develop new insights from hard-to-get company KPIs like:</>

        return (
        //     <Box
        //     id="hero"
        //     sx={{
        //       width: '100%',
        //       backgroundRepeat: 'no-repeat',
        //         // backgroundImage:
        //         //   `url(${HomeGradientSvg})`,
        //       }
        //     }
        //   >     
            <Stack sx={{mt: "0px"}} alignItems="center" border={0} m={0}>
                <Box border={0} sx={{display: {xs: 'none', md:'block', lg:'block'}}} >
                    <Typography variant="h1" align="center">
                        {text_headline}
                    </Typography>
                    {/* TODO Color */}
                    <Typography variant="h5" fontWeight="400" align="center" sx={{mt: "24px", color: "#00a8c4"}}>
                        {text_subheadline}
                    </Typography>
                </Box>
                <Box border={0} mt="24px" sx={{display: {xs: 'block', md:'none', lg:'none'}}} width="100vw">
                    <Typography fontSize="40px" fontWeight="600" letterSpacing="-0.2" lineHeight="40px" align="center" >
                        {text_headline}
                    </Typography>
                    <Typography fontSize="14px" fontWeight="400" lineHeight="28px" align="center" sx={{mt: "2vh", ml: "3vw", mr: "3vw", color: "#00a8c4"}}>
                            {text_subheadline}
                    </Typography>
                </Box>

                <Box sx={{display: {xs: 'none', md: 'block', lg: 'block'}, width: "100", mt: "1vh", mb: "2vh"}} p={2} border={0}>
                    {/* The reason why we use href="/signup" instead of component={RouterLink} to="/signup"
                    is that otherwise Authenticator's tab (signin vs create account) is not set to its initial state upon redirection.
                    This is a known limitation of Authenticator, so this is a workaround.*/}
                    <Button variant='contained'sx={{fontSize: "1.7rem", borderRadius: 50, background: 'linear-gradient(to right bottom, #4feaff,#E3FBE3)' }} size="large" href="/signup" onClick={() => onClickSignUpButton("main")}>Sign Up</Button>
                    <AlphaModal />
                </Box>
                <Box sx={{display: {xs: 'block', md: 'none', lg: 'none'}, width: "100", mt: "1vh", mb: "1vh"}} p={2} border={0}>
                    <Button variant='contained' href="/signup" sx={{background: 'linear-gradient(to right bottom, #4feaff,#E3FBE3)' }} onClick={() => onClickSignUpButton("main")}>Sign Up</Button>
                </Box>


                <Box sx={{display: {xs: 'none', md: 'block', lg: 'block'}}} width="56vw" maxWidth="1217.44px">
                    {/* <Typography variant="h5" fontWeight="400" align="center" sx={{mb: "20px"}}>
                        {text_plotheading}
                    </Typography> */}
                    <Carousel
                        indicatorContainerProps={{
                            style: {
                                marginTop: '0', // 5                            
                            }                                                        
                        }}
                        indicatorIconButtonProps={{
                            style: {
                                color: "#132D3D"
                            }
                        }}
                        activeIndicatorIconButtonProps={{
                            style: {
                                // backgroundColor: 'blue',
                                color: "var(--brand-main)" 
                            }
                        }}
                        navButtonsAlwaysInvisible={true}
                        animation="slide"
                    >
                        {
                            items.map( (item, i) => { item['fontSize'] = '1.25rem'; item['plotHeight'] = "45%"; return <CarouselItem key={i} {...item}/> })
                        }
                    </Carousel> 
                </Box>
                <Box sx={{display: {xs: 'block', md: 'none', lg: 'none'}}} width="90vw">
                    {/* <Typography fontSize="1.15rem" fontWeight="400" align="center" sx={{mb: "10px"}}>
                        {text_plotheading}
                    </Typography>                     */}
                    <Carousel
                        indicatorContainerProps={{
                            style: {
                                marginTop: '2vh', // 5                            
                            }                                                        
                        }}
                        indicatorIconButtonProps={{
                            style: {
                                color: "#132D3D"
                            }
                        }}
                        activeIndicatorIconButtonProps={{
                            style: {
                                // backgroundColor: 'blue',
                                color: "var(--brand-main)" 
                            }
                        }}
                        navButtonsAlwaysInvisible={true}
                        animation="slide"
                    >
                        {
                            items.map( (item, i) => { item['fontSize'] = '1.0rem'; item['plotHeight'] = "70%"; return <CarouselItem key={i} {...item}/> })
                        }
                    </Carousel>          
                </Box>
            </Stack>
            // </Box> 
        )
    };

    function ResponsiveFeatureSummarySection() {
        return (
            <Stack sx={{display: {xs: 'none', md: 'flex', lg: 'flex'}}} m={10} alignItems="center" border={0}>
                <Grid container alignItems="stretch" spacing={4} sx={{mt: "0"}}>
                    <Grid item display="flex" xs={12} md={4} lg={4}>
                        <Paper                       
                                sx={{ 
                                    width: 1,
                                    padding: "15px",
                                    borderRadius: "16px",
                                    // backgroundColor: "var(--revelata-paper-background-color)", 
                                    border: "1px solid var(--revelata-paper-border-color)",
                                }}>
                                <Stack direction="column" spacing={2}>
                                    <Stack direction="row" spacing={3}>
                                        <div><img alt="" src={SpeedSvg}/></div>
                                        <Typography variant="h3">Fast</Typography>
                                    </Stack>
                                    <Typography variant="h5">                                
                                        Say goodbye to manual data<br/>extraction.     
                                    </Typography>
                                </Stack>
                        </Paper>
                    </Grid>
                    <Grid item display="flex" xs={12} md={4} lg={4}>
                        <Paper 
                                sx={{ 
                                    width: 1,
                                    padding: "15px",
                                    borderRadius: "16px",
                                    // backgroundColor: "var(--revelata-paper-background-color)", 
                                    border: "1px solid var(--revelata-paper-border-color)",
                                }}>
                                <Stack direction="column" spacing={2}>
                                    <Stack direction="row" spacing={3}>
                                        <div><img alt="" src={TrustSvg}/></div><Typography variant="h3">Trusted</Typography>
                                    </Stack>
                                    <Typography variant="h5">                                
                                        Audit any data point with one click.
                                    </Typography>
                                </Stack>
                            </Paper>
                    </Grid>
                    <Grid item display="flex" xs={12} md={4} lg={4}>
                        <Paper sx={{ 
                                width: 1,
                                padding: "15px",
                                borderRadius: "16px",
                                // backgroundColor: "var(--revelata-paper-background-color)", 
                                border: "1px solid var(--revelata-paper-border-color)",
                            }}>
                            <Stack direction="column" spacing={2}>
                                <Stack direction="row" spacing={3}>
                                    <div><img alt="" src={EaseSvg}/></div><Typography variant="h3">Simple</Typography>
                                </Stack>
                                <Typography variant="h5">                                
                                    Export straight to Excel.&nbsp;&nbsp;Work offline. 
                                </Typography>
                            </Stack>        
                        </Paper>
                    </Grid>
                </Grid>
            </Stack>
        )
    }

    const onClickSignUpButton = (header) => {     
        ampli.signUpButtonClick({ "Button Location": header});             
    }

    function ResponsiveFeaturesSection() {

        function ResponsiveSection({section_id, header_color, header_img, header, text1, text2, text3, list, section_img}) {
            const mdlgBackgroundColor = (section_id % 2 === 1) ? "#121212" : "";
            const xsBackgroundColor = (section_id % 2 === 0) ? "#121212" : "";
                        
            return (
                <>                
                <Box sx={{display: {xs: 'none', md: 'flex', lg:'flex'}, 
                            backgroundColor: mdlgBackgroundColor, border: 0,
                            pt: "4vh", pb: "4vh", width: "100%"}} alignItems="center" justifyContent="center">
                        <Grid container justifyContent="center" alignItems="stretch">
                            {/* <Grid item md={1} lg={1} display="flex" justifyContent="center" /> */}
                            { section_id % 2 === 1 ? 
                                <Grid item md={5} lg={5} sx={{m:1}} display="flex" justifyContent="center">
                                    <img alt="" src={section_img}/>
                                </Grid>   
                                : null
                            }
                            <Grid item md={5} lg={5} justifyContent="center" maxWidth="800px">
                                <Stack direction="column" spacing={1} border={0}>
                                    <Stack direction="row" spacing={1}>
                                        <img alt="" src={header_img} height={33} width={33}/>
                                        <Typography variant="h6" color={header_color}>{header}</Typography>
                                    </Stack>
                                    <Typography variant="h4"  sx={{'fontSize': "2.65rem", fontWeight: '700'}}>{text1}</Typography>
                                    <Typography variant="h6" pt={4}  sx={{'fontSize': "1.25rem", fontWeight: '700', color: "var(--brand-main)"}}>{text2}</Typography>
                                    <Typography variant="h6" pb={2} sx={{'fontSize': "1.125rem", fontWeight: '600'}}>{text3}</Typography>
                                    <List dense={true} sx={{"padding-bottom": "24px"}}>            
                                        {
                                            list.map(
                                                (item) => <ListItem disableGutters sx={{fontWeight: '600'}}><ListItemIcon><CheckIcon/></ListItemIcon>{item}</ListItem>
                                            )
                                        }                                    
                                    </List>
                                    <Button variant='outlined' sx={{ width: "25%", fontSize: "1.125rem", fontWeight: '600'}} href="/signup" onClick={() => onClickSignUpButton(header)}>Sign Up</Button>
                                </Stack>                                
                            </Grid>
                            { section_id % 2 === 1 ? 
                                null
                                : 
                                <Grid item md={5} lg={5} sx={{m:1}} display="flex" justifyContent="center">
                                    <img alt="" src={section_img} />
                                </Grid>   
                            }
                            {/* <Grid item md={1} lg={1} display="flex" justifyContent="center"/> */}
                        </Grid>
                </Box>
                <Box sx={{display: {xs: 'flex', md: 'none', lg:'none'},
                            backgroundColor: xsBackgroundColor, 
                            pt: "4vh", pb: "4vh", pl: "3vw", pr: "3vw" }} alignItems="center" justifyContent="center">
                        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={5}>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Stack height="100%" direction="column" spacing={2} border={0}>
                                    <Stack direction="row" spacing={1}>
                                        <img alt="" src={header_img} height={33} width={33}/>
                                        <Typography variant="h5" color={header_color}>{header}</Typography>
                                    </Stack>
                                    <Typography variant="h4" sx={{'fontSize': "2.65rem", fontWeight: '700'}}>{text1}</Typography>
                                    <Typography pt={4}  sx={{'fontSize': "1.25rem", fontWeight: '700', color: "var(--brand-main)"}}>{text2}</Typography>
                                    <Typography pb={2} sx={{'fontSize': "1.125rem", fontWeight: '400'}}>{text3}</Typography>
                                    <List dense={true} sx={{"padding-bottom": "24px"}}>            
                                        {
                                            list.map(
                                                (item) => <ListItem disableGutters  sx={{fontWeight: '400'}}><ListItemIcon><CheckIcon/></ListItemIcon><ListItemText>{item}</ListItemText></ListItem>
                                            )
                                        }                                    
                                    </List>
                                    <Button variant='outlined' sx={{ width: "30vw", fontSize: "1.125rem", fontWeight: '600'}} href="/signup" onClick={() => onClickSignUpButton(header)}>Sign Up</Button>                                    
                                </Stack>                                
                            </Grid>
                        </Grid>
                </Box>
                </>
            )
        }

        const fast_section_props = {
            section_id: 0, 
            header_color: "#2CFECC",
            header_img: BoltSvg, 
            header: "Fast", 
            text1: "Cut days down to seconds.", 
            text2: "With deepKPI, the average analyst saves up to 1,200 hrs/yr.", 
            text3: "Our platform automatically discovers and extracts time series buried in text and across filings, resulting in a comprehensive set of detailed, company-specific KPIs that can't be found anywhere else.", 
            list: [
                "10,000+ companies", 
                "Up to 20 years of historicals",
                "Over 550 million data points and growing",
                "Automatic updates, whenever filings are published"
            ], 
            section_img: RevelataSystemSvg
        };

        // const trusted_section_props = {
        //     section_id: 1, 
        //     header_img: BoltSvg, 
        //     header: "Fast", 
        //     text1: "Cut days down to seconds.", 
        //     text2: "With deepKPI, the average analyst saves up to 1,200 hrs/yr.", 
        //     text3: "Our platform automatically discovers and extracts time series buried in text and across filings, resulting in a comprehensive set of detailed, company-specific KPIs that can't be found anywhere else.", 
        //     list: [
        //         "10,000+ companies", 
        //         "Up to 20 years of historicals",
        //         "Over 550 million data points and growing",
        //         "Automatic updates, whenever filings are published"
        //     ], 
        //     section_img: RevelataSystemSvg
        // };
        const trusted_section_props = {
            section_id: 1, 
            header_color: "#B354FE",
            header_img: ShieldSvg, 
            header: "Trusted", 
            text1: "Audit every data point with one click.", 
            text2: "We believe provenance is just as important as the data itself.", 
            text3: "Our customers must be able to trust our data.  What better way than to show them the exact sentence it came from?",
            list: [
                "Trace every data point back to its source sentence",                 
                "No generative AI = no hallucinations",                
                // "24/7 data quality monitor automatically flags possible issues before publication"
            ], 
            section_img: AuditUiSvg
        }

        const simple_section_props = {
            section_id: 2, 
            header_color: "#FE912C",
            header_img: UmbrellaSvg, 
            header: "Simple", 
            text1: "Works the way you do.", 
            text2: "deepKPI integrates seamlessly into your workflow.", 
            text3: "Incorporate our KPI time series into your existing models or build new ones around ours without skipping a beat.", 
            list: [
                "Export directly into Excel", 
                "Audit from within Excel with a single click",
                "Exports work offline, so you can share",                
            ], 
            section_img: ExcelIntegrationSvg
        }
        return (
            <Stack alignItems="center" justifyContent="center">
                <ResponsiveSection {...fast_section_props} />
                <ResponsiveSection {...trusted_section_props} />
                <ResponsiveSection {...simple_section_props} />
            </Stack>
        )
    }

    function ResponsiveFooter() {
        return (
            <>
            <Box sx={{display: {xs: 'none', md: 'block', lg: 'block'}, pl: 3, pr: 3, pt: 3, pb: 3}} border={0}>
                <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1}>
                    <img alt="" src={RevelataLogoSvg} height={33}/> 
                    {/* <Typography variant="h5" sx={{color: "var(--revelata-blue)"}}>revelata</Typography> */}
                </Stack>
                <Grid container direction="row" justifyContent="center" alignItems="stretch">
                    <Grid item xs={12} md={4} lg={4} display="flex">
                        <Typography sx={{fontSize: "0.8rem", color: "var(--mui-palette-primary-light)"}}>Copyright &copy; 2024, Revelata, Inc. All rights reserved. </Typography>                
                    </Grid>                
                    <Grid item xs={12} md={4} lg={4} display="flex" justifyContent="center">
                        <Link href="/static/tos.html" target="_blank" sx={{fontSize: "0.8rem", color: "var(--mui-palette-primary-light)"}}>Terms of Service</Link>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} justifyContent="end">

                    </Grid>
                </Grid>
                </Stack>            
            </Box>

            <Box sx={{display: {xs: 'block', md: 'none', lg: 'none'}, pl: 3, pr: 3, pt: 3, pb: 3}}>
                <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" border={0}>
                    <img alt="" src={RevelataLogoSvg} height={33}/> 
                    {/* <Typography variant="h5" sx={{color: "var(--revelata-blue)"}}>revelata</Typography> */}
                </Stack>
                <Grid container direction="row" justifyContent="center" alignItems="stretch" border={0}>
                    <Grid item xs={12} md={4} lg={4} display="flex" alignItems="center" justifyContent="center">
                        <Typography sx={{fontSize: "0.8rem", color: "var(--mui-palette-primary-light)"}}>Copyright &copy; 2024, Revelata, Inc. All rights reserved. </Typography>                
                    </Grid>                
                    <Grid item xs={12} md={4} lg={4} display="flex" justifyContent="center">
                        <Link href="/static/tos.html" target="_blank" sx={{fontSize: "0.8rem", color: "var(--mui-palette-primary-light)"}}>Terms of Service</Link>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} justifyContent="end">

                    </Grid>
                </Grid>
                </Stack>            
            </Box>
        </>
        )
    }

    return (
      <Container disableGutters maxWidth="false" alignItems="center">
        <ResponsiveLede />
        <ResponsiveFeatureSummarySection />
        <ResponsiveFeaturesSection />

        <Divider/>
        <ResponsiveFooter />

      </Container>
    )
}