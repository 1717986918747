import './../../App.css';
import htmlclean from 'htmlclean';
import pako from 'pako';
import { useState, useEffect, useContext, useCallback } from 'react';

import { Box, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';

import { ALLOWED_TAG_LIST, Interweave } from 'interweave';

import { ErrorBox } from '../ErrorBox';
import { API_URL, makePostRequest } from '../RestApi';
import { DisplayedCikContext, SelectedDatapointContext } from './Contexts';
import { longestPrefixInString } from '../Utils';

// Info bar shown above the filing 
function InfoBar({cik, selectedDatapoint}) {        
    // console.log("InfoBar ", cik, selectedDatapoint);
    if (selectedDatapoint && selectedDatapoint['dp']) {
        const dp = selectedDatapoint['dp'];       
        const displayFormDocType = dp['formType'] + (dp['formType'] === dp['docType'] ? "" : " (" + dp['docType'] + ")")
        const displayFormLabel = dp['formType'] === dp['docType'] ? "Form Type" : "Form Type (Document Type)"
        return (
            <Grid container 
                height="115px" 
                minHeight="115px" 
                direction="column"
                alignItems="center" 
                justifyContent="center" 
                sx={{p: "8px", border: "solid", borderWidth: "0px"}}>                               
                <Grid item xs={4} align="center" sx={{ border: "solid", borderWidth: "0px"}}> 
                    <Typography variant="h7">{displayFormLabel}</Typography>
                    <Typography variant="h6" color="var(--brand-main)">{displayFormDocType}</Typography>                    
                </Grid>
                <Grid item xs={4} align="center" sx={{ border: "solid", borderWidth: "0px"}}>                     
                    <Typography variant="h7">Filing Date</Typography>
                    <Typography variant="h6" color="var(--brand-main)">{dp['filingDate']}</Typography>
                </Grid>
                <Grid item xs={4} align="center" sx={{ border: "solid", borderWidth: "0px"}}> 
                    <Typography variant="h7">Reporting Period</Typography>
                    <Typography variant="h6" color="var(--brand-main)">{dp['reportingPeriod']}</Typography>
                </Grid>
            </Grid>
        )
    }
    return "Source Filing"
}

export function ProvenancePane({provenancePaneHeight}) {    
    // const [accessToken, setAccessToken] = useState(null);
    
    const [data, setData] = useState(null);
    const [apiWait, setApiWait] = useState(false);
    const [errorState, setErrorState] = useState(false);

    const [displayedCik, setDisplayedCik] = useContext(DisplayedCikContext);
    const [selectedDatapoint, setSelectedDatapoint ] = useContext(SelectedDatapointContext);
    // const [fromDate, toDate] = useContext(DateRangeContext);
    //const [pv, setPv] = useState(['/html/body/div[3]/div/p[4]', '/html/body/div[3]/div/p[5]']);
    //const [pv, setPv] = useState([]);
    //const [targetSent, setTargetSent] = useState('Accordingly, the total royalty payments received by Mesabi Trust on July 30, 2020 from Cliffs were $4,349,830. The royalties paid to Mesabi Trust are based on the volume of shipments of iron ore pellets for the quarter and the year to date, the pricing of iron ore product sales, and the percentage of iron ore pellet shipments from Mesabi Trust lands rather than from non-Mesabi Trust lands.')


    const autoScrollCallback = useCallback(
        (node) => {
            node?.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "center"
            });
        }, []
    );

    // When selectedDatapoint changes, hit the API or clear the display
    useEffect(        
        () => {
            //@@@TODO recurse on node
            // function processNode(node, target) {
            //     let src = node.innerHTML;                                
            //     let highlightSpans = [];                    
            //     let srcMatchStart = 0, srcMatchLen = 0, srcMatchEnd = 0; 
            //     let prevSrcMatchEnd = 0;
            //     let spanCounter = 0;

            //     let count = 0;
            //     while (tgt.length > 0 && src.length > 0) {
            //         // console.log('loop ', count);
            //         // console.log(tgt);
            //         // console.log(src);

            //         [ srcMatchStart, srcMatchLen ] = longestPrefixInString(src, tgt);
            //         if (srcMatchStart < 0) {
            //             break;
            //         }  

            //         srcMatchEnd = srcMatchStart + srcMatchLen;
            //         // console.log("LP: ", srcMatchStart, srcMatchEnd);                        
            //         // console.log("LP: ", src.substring(srcMatchStart, srcMatchEnd));
            //         // console.log("LP in orig coords: ", prevSrcMatchEnd+srcMatchStart, prevSrcMatchEnd+srcMatchEnd);
            //         // console.log("LP in orig:", node.innerHTML.substring(prevSrcMatchEnd+srcMatchStart, prevSrcMatchEnd+srcMatchEnd));

            //         highlightSpans.push([prevSrcMatchEnd+srcMatchStart, prevSrcMatchEnd+srcMatchEnd]);

            //         // prevSrcMatchStart = prevSrcMatchEnd + srcMatchStart; 
            //         prevSrcMatchEnd = prevSrcMatchEnd + srcMatchEnd;
            //         src = src.substring(srcMatchEnd, src.length);
            //         tgt = tgt.substring(srcMatchLen, tgt.length).trim();


            //         count = count + 1;
            //         //@@@TODO Detect failure
            //         if (count > 10) {
            //             break;
            //         }

            //         // console.log("tgt.length", tgt.length);
            //         // console.log("src.length", src.length);
            //     } 
            //     // console.log(src);
            //     // console.log(tgt);

            //     // console.log("HIGHLIGHT:", node.innerHTML.substring(highlightSpans[0][0],                    
            //     // console.log(highlightSpans);
            //     const prefix = node.innerHTML.substring(0, highlightSpans[0][0]);
            //     const highlight = node.innerHTML.substring(highlightSpans[0][0], highlightSpans[highlightSpans.length - 1][1]);
            //     const suffix = node.innerHTML.substring(highlightSpans[highlightSpans.length - 1][1], node.innerHTML.length);

            //     // console.log(prefix);
            //     // console.log(highlight);
            //     // console.log(suffix);

            //     node.innerHTML = prefix + `<span id="revelata-highlight-${spanCounter}">` + highlight + '</span>' + suffix;
            //     spanCounter += 1;
            // }

            function processHtml(html) {        
                // console.log("insertHighlightSpans, selectedDatapoint", selectedDatapoint);
                const doc = document.implementation.createHTMLDocument('InsertHighlights');        
                const el = doc.documentElement;
                el.innerHTML = htmlclean(html);

                // // ---- Unwrap XBRL tag if it is wrapping the entire document ---------------------------------
                // const body_el = el.getElementsByTagName('BODY');
                // if (body_el.length && body_el[0].children && body_el[0].children[0].tagName == 'XBRL') {
                //     body_el[0].children[0].replaceWith(...body_el[0].children[0].children);
                // }
        
                // ---- Change relative img src to absolute URL -----------------------------------------------
                const img_nodes = doc.getElementsByTagName('img');
                // console.log(img_nodes);
                for (let i = 0; i < img_nodes.length; i++) {
                    if (img_nodes[i].src.substring(0, 4) === "http") {
                        continue;
                    } 
                    const accno = selectedDatapoint['dp']['accNo'].replaceAll('-', '');
                    const base_url = "https://www.sec.gov/Archives/edgar/data/" + displayedCik.toString() + "/" + accno + "/";
                    img_nodes[i].src = base_url + img_nodes[i].src;
                }
                
                // ---- Insert highlighting spans -----------------------------------------------
        
                // let tgt = targetSent;
                let tgt = selectedDatapoint['dp']['sentence'];
                let spanCounter = 0;
                const pv = selectedDatapoint['dp']['xpathNodes'].map((x) => {return x['xpath'];});
        
                pv.forEach(
                    (xp) => {
                        // // Remove any occurrence of '/xbrl' in the xpath since this is an artefact
                        // // introduced by markdownify using html5lib as its default html parser. 
                        // xpath = xpath.replace('/xbrl', ''); 

                        let node = doc.evaluate("/"+xp, doc, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
                        // console.log(xp, node);

                        // When ix element
                        if (!node) {
                            return;
                        }

                        try {
                            let src = node.innerHTML;
                            // console.log(node.childNodes);
                            
                            let highlightSpans = [];                    
                            let srcMatchStart = 0, srcMatchLen = 0, srcMatchEnd = 0; 
                            // let prevSrcMatchStart = 0;
                            let prevSrcMatchEnd = 0;
                            
                            let count = 0;
                            while (tgt.length > 0 && src.length > 0) {
                                // console.log('loop ', count);
                                // console.log(tgt);
                                // console.log(src);
        
                                [ srcMatchStart, srcMatchLen ] = longestPrefixInString(src, tgt);
                                if (srcMatchStart < 0) {
                                    break;
                                }  
        
                                srcMatchEnd = srcMatchStart + srcMatchLen;
                                // console.log("LP: ", srcMatchStart, srcMatchEnd);                        
                                // console.log("LP: ", src.substring(srcMatchStart, srcMatchEnd));
                                // console.log("LP in orig coords: ", prevSrcMatchEnd+srcMatchStart, prevSrcMatchEnd+srcMatchEnd);
                                // console.log("LP in orig:", node.innerHTML.substring(prevSrcMatchEnd+srcMatchStart, prevSrcMatchEnd+srcMatchEnd));
        
                                highlightSpans.push([prevSrcMatchEnd+srcMatchStart, prevSrcMatchEnd+srcMatchEnd]);
        
                                // prevSrcMatchStart = prevSrcMatchEnd + srcMatchStart; 
                                prevSrcMatchEnd = prevSrcMatchEnd + srcMatchEnd;
                                src = src.substring(srcMatchEnd, src.length);
                                tgt = tgt.substring(srcMatchLen, tgt.length).trim();
                                count = count + 1;
                                //@@@TODO Detect failure
                                if (count > 10) {
                                    break;
                                }
                                // console.log("tgt.length", tgt.length);
                                // console.log("src.length", src.length);
                            } 
                            // console.log(src);
                            // console.log(tgt);
        
                            // console.log("HIGHLIGHT:", node.innerHTML.substring(highlightSpans[0][0],    
                            
                            //When we have already covered the entire sentence.
                            if (highlightSpans.length === 0) {
                                return;
                            }
                            const prefix = node.innerHTML.substring(0, highlightSpans[0][0]);
                            const highlight = node.innerHTML.substring(highlightSpans[0][0], highlightSpans[highlightSpans.length - 1][1]);
                            const suffix = node.innerHTML.substring(highlightSpans[highlightSpans.length - 1][1], node.innerHTML.length);
        
                            // console.log(prefix);
                            // console.log(highlight);
                            // console.log(suffix);
        
                            node.innerHTML = prefix + `<revspan id="revelata-highlight-${spanCounter}">` + highlight + '</revspan>' + suffix;
                            spanCounter += 1;
                            
                            //console.log(node.innerHTML);
                        } catch (error) {                                        
                            console.log(error);
                            return html;                    
                        }
                    }
                )                
                return el.innerHTML.trim();
            }

            console.log("selectedDatapoint:", selectedDatapoint);
            if (selectedDatapoint) {                
                setData(null);     // clears the display
                setApiWait(true);  // set waiting state to show spinners 
                setErrorState(false); // clear error state                                

                const acc_no = selectedDatapoint['dp']['accNo'];
                const seq_no = selectedDatapoint['dp']['seqNo'].toString(); 

                const requestPayload = {
                    'acc_no': acc_no,
                    'seq_no': seq_no
                };

                makePostRequest(API_URL+'/document', null, requestPayload)
                    .then(
                        response => {
                            //console.log("API response:", response);
                            setData(response); 

                            // Decode base64 and gunzip
                            const gzbuf = new Uint8Array(atob(response['html']).split("").map(function(c) {
                                return c.charCodeAt(0); 
                            }));
                            const html = pako.inflate(gzbuf, {"to": "string"});
                            
                            // Prepend an HTML comment to circumvent Interweave throwing an error when __DEV__ is true. See:
                            // https://github.com/milesj/interweave/blob/36117a7832026f8e43f3a55b5a17167d14592a35/packages/core/src/Parser.ts#L278
                            setData("<!--revelata.com-->" + processHtml(html));
                            setApiWait(false); // Got response, so no longer waiting
                            setErrorState(false);
                        }
                    )
                    .catch(error => {
                        console.log("API ERROR: ", error)
                        setErrorState(true);
                    })
            } else {
                // If null selectedDatapoint, clear & reset (e.g., when user runs new search).
                setData(null);
                setApiWait(false);
                setErrorState(false);
            }
        }, 
        [displayedCik, selectedDatapoint]
    );
    
    // useEffect( 
    //     () => {
    //         console.log("data changed: ", data);
    //     }, [data]
    // )

    // Error handler
    if (errorState) {
        return <ErrorBox />
    }

    // Show spinner when waiting for API response
    if (apiWait && data === null) {
        return (
            <Stack alignItems="center" justifyContent="center" height="100%">
                <CircularProgress disableShrink={true} />
            </Stack>
        )
    }    

    // If no selected datapoint, then show nothing
    if (data === null) {
        return null;
    } 

    // Otherwise, we have the API response and a selected datapoint, so display the provenance
    // document


    // Interweave transform our highlighted spans in into ReactElements so we can autoscroll.
    function transform(node, children, config) {
        if (node.tagName !== "REVSPAN") { return; }

        if (node.id === "revelata-highlight-0") {
            return <span ref={autoScrollCallback} style={{scrollMargin: "100px", backgroundColor: 'yellow'}}>{children}</span>;
        } else if (node.id.includes("revelata-highlight-")) {
            return <span style={{backgroundColor: 'yellow'}}>{children}</span>;
        }
    }

    // Prepend an HTML comment to circumvent Interweave throwing an error when __DEV__ is true. See:
    // https://github.com/milesj/interweave/blob/36117a7832026f8e43f3a55b5a17167d14592a35/packages/core/src/Parser.ts#L278
    // const payloadhtml = "<!--revelata.com-->" + insertHighlightSpans(data['html']); 
    // const payloadhtml = insertHighlightSpans(data['html']); 

    return (
        <Box>
            <Paper sx={{borderBottom: 1, borderColor: "grey.500", padding: "0px", borderRadius: "0px", backgroundColor: "var(--mui-palette-background-default)"}}>
                <InfoBar cik={displayedCik} selectedDatapoint={selectedDatapoint}/>
            </Paper>            
            {/* <ThemeProvider theme={lightTheme}> */}
                <Paper square elevation={0} sx={{paddingLeft: "10px", paddingRight: "10px", backgroundColor: "white", color: "black", height: provenancePaneHeight, maxHeight: provenancePaneHeight, overflowY: "auto"}}>                    
                    <Interweave content={data} allowList={ALLOWED_TAG_LIST.concat(['revspan'])} transformOnlyAllowList={true} transform={transform}/>                    
                </Paper>                
            {/* </ThemeProvider> */}
        </Box>
    );
};

export default ProvenancePane;

// /html/body/div[3]/div/p[4]


